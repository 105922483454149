import {AfterViewChecked, Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appScroll]',
  standalone: true
})
export class ScrollDirective implements AfterViewChecked {
  @Output() setTime: EventEmitter<string> = new EventEmitter<string>();
  @Input('appScroll') activeBlock!: HTMLDivElement;

  constructor(private el: ElementRef) {}

  ngAfterViewChecked(): void {
    this.checkVisibility();
  }

  @HostListener('scroll', ['$event'])
  onScroll(): void {
    this.checkVisibility();
  }

  private checkVisibility(): void {
    const container = this.el.nativeElement;
    const items = container.querySelectorAll('.time-wrapper');

    const scrollContainerStyle = container.style;
    scrollContainerStyle.scrollSnapType = 'y mandatory';

    items.forEach((item: HTMLElement): void => {
      const isIntersecting: boolean = this.areElementsInSamePosition(item, this.activeBlock);

      if (isIntersecting) {
        this.emitSetTime(item.innerText);
      }

      if (item.innerText) {
        item.removeEventListener('click', this.scrollToItemHandler);
        item.addEventListener('click', this.scrollToItemHandler);
      }
    });
  }

  private areElementsInSamePosition(element1: HTMLElement, element2: HTMLElement): boolean {
    const rect1 = element1.getBoundingClientRect();
    const rect2 = element2.getBoundingClientRect();
    // number 5 is threshold
    return Math.abs(rect1.top - rect2.top) < 5
  }

  private emitSetTime(text: string): void {
    this.setTime.emit(text);
  }

  private scrollToItemHandler = (event: MouseEvent): void => {
    const item: HTMLElement = event.currentTarget as HTMLElement;
    item.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    this.setTime.emit(item.innerText);
  };
}
