<div class="selected-time">
  {{ hour }}:{{ minute }} {{ ante }}
</div>

<div class="time-types">
  <div class="col-name">Hour</div>
  <div class="col-name">Minute</div>
  <div class="col-name">AM/PM</div>
</div>
<div class="divider"></div>
<div class="time-block">
  <div class="active-block" #activeBlock></div>
  <div class="time-list" [appScroll]="activeBlock" (setTime)="setTime($event, 'hour')">
    @for (hour of hours; track hour) {
      @if (hour) {
        <div class="time-wrapper" matRipple>
          {{ hour }}
        </div>
      } @else {
        <div class="time-wrapper empty"></div>
      }
    }
  </div>
  <div class="time-list" [appScroll]="activeBlock" (setTime)="setTime($event, 'minute')">
    @for (minute of minutes; track minute) {
      @if (minute) {
        <div class="time-wrapper" matRipple [class.empty]="!minute">
          {{ minute }}
        </div>
      } @else {
        <div class="time-wrapper empty"></div>
      }
    }
  </div>
  <div class="time-list" [appScroll]="activeBlock" (setTime)="setTime($event, 'ante')">
    @for (ante of antes; track ante) {
      @if (ante) {
        <div class="time-wrapper" matRipple [class.empty]="!ante">
          {{ ante }}
        </div>
      } @else {
        <div class="time-wrapper empty"></div>
      }
    }
  </div>
</div>
