import {booleanAttribute, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";
import {MatRipple} from "@angular/material/core";

@Component({
  selector: 'app-label',
  standalone: true,
  imports: [
    MatIcon,
    MatTooltip,
    MatMenu,
    MatMenuTrigger,
    MatRipple
  ],
  templateUrl: './label.component.html',
  styleUrl: './label.component.scss'
})
export class LabelComponent {
  @Input() label?: string;
  @Input() required?: boolean;
  @Input({transform: booleanAttribute}) dropdown: boolean = false;
  @Input() hint: string = '';
  @Input() activeColorId: number | null = null;
  @Input() isInvalid: boolean = true;
  @Input() colors: {code: string, id: number, name: string}[] = [];
  @Input() dropdownId: number | null = null;
  @Input() baseEntity: string | null = null;
  @Output() onColorSet: EventEmitter<{code: string, id: number, name: string}> = new EventEmitter();

  public create(): void {
    window.open(`dashboard/form/${this.baseEntity}`, "_blank");
  }

  public edit(): void {
    (window as any).open(`dashboard/form/${this.baseEntity}/?id=${this.dropdownId}` , "_blank");
  }
}
