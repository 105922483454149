import {AfterViewInit, Component, inject, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {BaseComponent} from "../base/base.component";
import {LabelComponent} from "../label/label.component";
import {MatFormField} from "@angular/material/form-field";

@Component({
  selector: 'app-text',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgTemplateOutlet,
    NgIf,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    NgClass,
    LabelComponent,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, {skipSelf: true})
    }
  ],
  templateUrl: './text.component.html',
  styleUrl: './text.component.scss',
})
export class TextComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('controlRef') controlRef?: MatFormField;
  public name: string = '';
  public isViewMode: boolean = false;

  ngAfterViewInit(): void {
    if (!this.isViewMode) {
      if (this.group.get(this.name)?.get('color_id')?.value === null) {
        this.group.get(this.name)?.get('color_id')?.setValue(this.defaultColorId);
      }
      this.setEditModeColor(this.group.get(this.name)?.get('color_id')?.value, this.controlRef);
    }
  }

  public setRawValidators(validators: any[]): void {
    this.setValidations(validators);
  }

  public setValidations(validators: any[]): void {
    let validatorsToAdd: ValidatorFn[] = [];
    this.validators = validators;
    this.setValidators(this.validators, validatorsToAdd);
    this.group?.get(this.name)?.get('value')?.setValidators(validatorsToAdd);
  }

  public setValidators(validators: any[], validatorsToAdd: ValidatorFn[]): void {
    validators.forEach((validation: any): void => {
      switch (validation.name) {
        case 'required':
          if (Boolean(validation.value[0])) {
            this.required = true;
            validatorsToAdd.push(Validators.required);
          }
          break;
        case 'min':
          validatorsToAdd.push(Validators.minLength(Number(validation.value[0])));
          break;
        case 'max':
          validatorsToAdd.push(Validators.maxLength(Number(validation.value[0])));
          break;
      }
    });
  }

  public checkForErrorsIn(formControl: AbstractControl): string | undefined {
    if (formControl?.get('value')?.hasError('required')) {
      return this.validators.find(validator => validator.name === 'required')?.error_message;
    }
    if (formControl.get('value')?.hasError('minlength')) {
      return this.validators.find(validator => validator.name === 'min')?.error_message;
    }
    if (formControl.get('value')?.hasError('maxlength')) {
      return this.validators.find(validator => validator.name === 'max')?.error_message;
    }
    return '';
  }
}
