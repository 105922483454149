@if (viewMode) {
  <div class="form-field-view-mode">
    <div class="label">
      <span class="label-text" [matTooltip]="label"> {{ label }}</span>
      <mat-icon svgIcon="hint" [matTooltip]="hint" class="icon"></mat-icon>
    </div>
    <div class="value" [style.background-color]="setViewModeColor(group.get(name)?.value['color_id'])">
      {{ group.get(name)?.value['value'] }}
    </div>
  </div>
} @else {
  <app-label
  [label]="label"
  [required]="required"
  [hint]="hint"
  [activeColorId]="group.get(name)?.value['color_id']"
  [isInvalid]="(group.get(name)?.invalid === true) && (group.get(name)?.touched === true)"
  [colors]="colors"
  (onColorSet)="onColorSet(name, $event, controlRef)"
  />
  <mat-form-field [formGroupName]="name" class="customised-mat-input" #controlRef
                  [ngClass]="isViewMode ? 'view' : 'create'">
    <input class="value" matInput formControlName="value" [placeholder]="placeholder">
    <mat-error>{{ checkForErrorsIn(group.get(name)!) }}</mat-error>
  </mat-form-field>
}
