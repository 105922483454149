<div class="customised-mat-label">
  <div class="left-col">
    <span class="left-col-text" [class.invalid]="isInvalid"> {{ label }} </span>
    @if (required) {
      <span class="required">*</span>
    }
    <mat-icon svgIcon="hint" class="left-col-icon" [matTooltip]="hint"/>
  </div>
  <div class="right-col">
    <mat-icon svgIcon="color-status-icon" class="color-status-icon" [matMenuTriggerFor]="menu"/>
    <mat-menu #menu="matMenu" xPosition="before" class="status-customised-mat-menu">
      <div class="status-container">
        @for (color of colors; track color) {
          <div class="status-wrapper" matRipple (click)="onColorSet.emit(color)">
            <div class="status-wrapper-color" [style.background-color]="color.code">
              @if (color.id === activeColorId) {
                <mat-icon svgIcon="checked" class="checked-icon"></mat-icon>
              }
            </div>
            <div class="status-wrapper-name">{{ color.name }}</div>
          </div>
        }
      </div>
    </mat-menu>
    @if (dropdown) {
      @if (dropdownId) {
        <mat-icon svgIcon="edit-pencil-icon" class="add-icon" (click)="edit()"/>
      } @else {
        <mat-icon svgIcon="add-icon" class="add-icon" (click)="create()"/>
      }
    }
  </div>
</div>
