import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatRippleModule} from "@angular/material/core";
import {ScrollDirective} from "../../../../core/directive/scroll.directive";

@Component({
  selector: 'app-time',
  standalone: true,
  imports: [
    MatRippleModule,
    ScrollDirective
  ],
  templateUrl: './time.component.html',
  styleUrl: './time.component.scss'
})
export class TimeComponent implements OnInit {
  @Output() setGlobalTime: EventEmitter<{hour: string, minute: string, ante: string}> = new EventEmitter();
  public hours: any[] = [];
  public minutes: any[] = [];
  public antes: any[] = ['am', 'pm'];
  public hour: string = '00';
  public minute: string = '00';
  public ante: string = 'AM';

  ngOnInit(): void {
    for (let i = 0; i < 6; i++) {
      if (i > 2) {
        this.antes[i] = null;
      }
    }
    for (let i = 0; i < 28; i++) {
      if (i < 24) {
        let hour = i < 10 ? '0' + i : i;
        this.hours[i] = hour.toString();
      } else {
        this.hours[i] = null;
      }
    }
    for (let i = 0; i < 64; i++) {
      if (i < 60) {
        let hour = i < 10 ? '0' + i : i;
        this.minutes[i] = hour.toString();
      } else {
        this.minutes[i] = null;
      }
    }
  }

  public setTime(event: string, type: string): void {
    switch (type) {
      case 'hour':
        this.hour = event;
        break;
      case 'minute':
        this.minute = event;
        break;
      case 'ante':
        this.ante = event;
        break;
    }
    this.setGlobalTime.emit({hour: this.hour, minute: this.minute, ante: this.ante});
  }
}
