@if (viewMode) {
  <div class="form-field-view-mode">
    <div class="label">
      <span class="label-text" [matTooltip]="label"> {{ label }}</span>
      <mat-icon svgIcon="hint" [matTooltip]="hint" class="icon"></mat-icon>
    </div>
    <div class="value" [style.background-color]="setViewModeColor(group.get(name)?.value['color_id'])">
      {{ group.get(name)?.value['value'] }}</div>
  </div>
} @else {
  <app-label
    [label]="label"
    [required]="required"
    [hint]="hint"
    [activeColorId]="group.get(name)?.value['color_id']"
    [colors]="colors"
    [isInvalid]="(group.get(name)?.invalid === true) && (group.get(name)?.touched === true)"
    (onColorSet)="onColorSet(name, $event, controlRef)"
  />
  <mat-form-field #controlRef [formGroupName]="name" class="customised-mat-input" [ngClass]="isViewMode ? 'view' : 'create'">
    <input class="value" matInput formControlName="value" [matMenuTriggerFor]="calendarMenu" [placeholder]="placeholder">
    <mat-error>{{ checkForErrorsIn(group.get(name)!) }}</mat-error>
    <mat-icon matSuffix svgIcon="calendar-icon" class="calendar-icon" [matMenuTriggerFor]="calendarMenu"></mat-icon>
  </mat-form-field>
  <mat-menu #calendarMenu="matMenu" class="customised-mat-menu" yPosition="below" [overlapTrigger]="true">
    <div class="calendar-menu">
      <div class="calendar-menu-content" (click)="$event.stopPropagation();">
        @if (props.time === 'true') {
          <div class="calendar-menu-content-routes">
            @if (activeTab === 'date') {
              <button mat-raised-button color="primary" class="btn" (click)="dateTime('date')">Date</button>
              <button mat-button color="primary" class="btn bg-white" (click)="dateTime('time')">Time</button>
            } @else {
              <button mat-button color="primary" class="btn bg-white" (click)="dateTime('date')">Date</button>
              <button mat-raised-button color="primary" class="btn" (click)="dateTime('time')">Time</button>
            }
          </div>
        }
        @if (activeTab === 'date') {
          <mat-calendar [(selected)]="selected" [minDate]="minDate" [maxDate]="maxDate"
                        class="customised-calendar"></mat-calendar>
        } @else {
          <app-time (setGlobalTime)="setTime($event)"/>
        }
      </div>
      <div class="calendar-menu-actions">
        <button mat-button class="btn">Cancel</button>
        <mat-divider vertical></mat-divider>
        <button mat-button class="btn" color="primary" (click)="setCollectedDate()">Set</button>
      </div>
    </div>
  </mat-menu>
}
