import {AfterViewInit, Component, inject, Injectable, ViewChild} from '@angular/core';
import {MatMenuModule, MatMenuTrigger} from "@angular/material/menu";
import {
  AbstractControl,
  ControlContainer, FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {
  DateAdapter,
  MatNativeDateModule,
  MatRippleModule, NativeDateAdapter,
} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {TimeComponent} from "./time/time.component";
import {BaseComponent} from "../base/base.component";
import {DatePipe, NgClass} from "@angular/common";
import {LabelComponent} from "../label/label.component";

@Injectable()
class CustomDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }
}

@Component({
  selector: 'app-calendar',
  standalone: true,
    imports: [
        MatMenuModule,
        FormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatDividerModule,
        MatRippleModule,
        TimeComponent,
        NgClass,
        LabelComponent,
    ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, {skipSelf: true})
    }
  ],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
})
export class CalendarComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('controlRef') controlRef?: MatFormField;
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  public name: string = '';
  public isViewMode: boolean = false;
  public activeTab: 'date' | 'time' = 'date';
  public selected: string = '';
  public minDate: any | null = null;
  public maxDate: any | null = null;
  public time = {hour: '00', minute: '00', ante: 'am'};
  private datePipe: DatePipe = inject(DatePipe);

  ngAfterViewInit(): void {
    if (!this.isViewMode) {
      if (this.group.get(this.name)?.get('color_id')?.value === null) {
        this.group.get(this.name)?.get('color_id')?.setValue(this.defaultColorId);
      }
      this.setEditModeColor(this.group.get(this.name)?.get('color_id')?.value, this.controlRef);
    }
  }

  public dateTime(tab: 'date' | 'time'): void {
    this.activeTab = tab;
  }

  public setRawValidators(validators: any[]): void {
    this.setValidations(validators);
  }

  public setValidations(validators: any[]): void {
    let validatorsToAdd: ValidatorFn[] = [];
    this.validators = validators;
    this.setValidators(this.validators, validatorsToAdd);
    this.group?.get(this.name)?.get('value')?.setValidators(validatorsToAdd);
  }

  public setTime(event: { hour: string, minute: string, ante: string }): void {
    this.time = event;
  }

  public setCollectedDate(): void {
    let date = this.datePipe.transform(this.selected, 'YYYY-MM-dd');
    if (this.props.time === 'true') {
      // ${this.time.ante}
      date += ` ${this.time.hour}:${this.time.minute}`;
    }
    (this.group.get(this.name) as FormGroup).get('value')?.setValue(date);
  }

  public setValidators(validators: any[], validatorsToAdd: ValidatorFn[]): void {
    validators.forEach((validation: any): void => {
      switch (validation.name) {
        case 'required':
          if (Boolean(validation.value[0])) {
            this.required = true;
            validatorsToAdd.push(Validators.required);
          }
          break;
        case 'min':
          validatorsToAdd.push(Validators.minLength(Number(validation.value[0])));
          break;
        case 'max':
          validatorsToAdd.push(Validators.maxLength(Number(validation.value[0])));
          break;
        case 'min_date': {
          const parsedDate = validation.value[0];
          if (parsedDate.hasOwnProperty('type') && parsedDate.type === 'relative') {
            if (parsedDate.hasOwnProperty('value')) {
              let date = new Date();
              date.setDate(date.getDate() + parsedDate.value);
              this.minDate = date;
              validatorsToAdd.push(this.minDateValidator('min_date'));
              return;
            }
            this.minDate = new Date();
          }
        }
          break;
        case 'max_date': {
          const parsedDate = validation.value[0];
          if (parsedDate.hasOwnProperty('type') && parsedDate.type === 'relative') {
            if (parsedDate.hasOwnProperty('value')) {
              let date = new Date();
              date.setDate(date.getDate() + parsedDate.value);
              this.maxDate = date;
              validatorsToAdd.push(this.maxDateValidator('max_date'));
              return;
            }
            this.maxDate = new Date();
          }
        }
          break;
      }
    });
  }

  public checkForErrorsIn(formControl: AbstractControl): string | undefined {
    if (formControl.get('value')?.hasError('required')) {
      return this.validators.find(validator => validator.name === 'required')?.error_message;
    }
    if (formControl.get('value')?.hasError('minlength')) {
      return this.validators.find(validator => validator.name === 'min')?.error_message;
    }
    if (formControl.get('value')?.hasError('maxlength')) {
      return this.validators.find(validator => validator.name === 'max')?.error_message;
    }
    if (formControl.get('value')?.hasError('min_date')) {
      return this.validators.find(validator => validator.name === 'min_date')?.error_message;
    }
    if (formControl.get('value')?.hasError('max_date')) {
      return this.validators.find(validator => validator.name === 'max_date')?.error_message;
    }
    return '';
  }

  private maxDateValidator(error: string): ValidatorFn {
    return (control: AbstractControl): { [p: string]: { value: any } } | null => {
      return this.isDateAfterToday(control.value) ? {[error]: {value: control.value}} : null;
    };
  }

  private minDateValidator(error: string): ValidatorFn {
    return (control: AbstractControl): { [p: string]: { value: any } } | null => {
      return this.isDateBeforeToday(control.value) ? {[error]: {value: control.value}} : null;
    };
  }

  private isDateBeforeToday(date: string): boolean {
    return new Date(new Date(date).toDateString()) < new Date(new Date().toDateString());
  }

  private isDateAfterToday(date: string): boolean {
    return new Date(new Date(date).toDateString()) > new Date(new Date(this.maxDate).toDateString());
  }
}
